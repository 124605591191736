import SectionTitle from 'components/global/SectionTitle'
import { Col, Row, Section } from '../grid'
import { H3, H4, H5 } from '../typography/Headings'
import React from 'react'
import { P } from 'components/typography/Paragraph'
import { twMerge } from 'tailwind-merge'

const items = [
	{
		title: 'Digital-First Curriculum',
		description: 'Ensures teachers can lead every ELA lesson with confidence; includes supports for MLLs and striving readers',
		image: '/images/arc-core-fusion/ms-2024-materials-digital-curriculum.png',
		borderColor: 'border-t-ab-100'
	},
	{
		title: 'Core Texts',
		description: 'Class sets of multiple high-quality, authentic texts anchor the work of each Unit',
		image: '/images/arc-core-fusion/ms-2024-materials-core-texts.png',
		borderColor: 'border-t-core-or'
	},
	{
		title: 'Unit Library',
		description: 'Related texts at a range of text complexities provide choice for research and cross-textual analysis',
		image: '/images/arc-core-fusion/ms-2024-materials-unit-library.png',
		borderColor: 'border-t-core-g'
	},
	{
		title: 'Interactive Digital Platform',
		description: 'Hosts the collaborative intellectual community in which students read, write, research, and debate',
		image: '/images/arc-core-fusion/ms-2024-materials-interactive-platform.png',
		borderColor: 'border-t-ar-100'
	}
]

export default function EachUnitSection() {
	return (
		<Section margin="mt-20 md:mt-40">
			<SectionTitle title="Each Unit Includes" text="Grade 6: Unit 1, World Mythology Example" />
			<Row>
				{items.map((item) => (
					<Col key={item.title} width="w-full md:w-1/2 lg:w-1/4" className="mb-8">
						<div className={twMerge('border rounded shadow-sm p-4 border-t-4 h-full flex flex-col', item.borderColor)}>
							<img src={item.image} alt={item.title} className="" />
							<span className="text-lg font-bold mt-2">{item.title}</span>
							<P size="sm" className="mt-1">
								{item.description}
							</P>
						</div>
					</Col>
				))}
				<p className="text-center w-full text-sm text-gray-500 italic">Materials shown are a representative sample. Actual titles may vary based on availability.</p>
			</Row>
		</Section>
	)
}
