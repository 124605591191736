import { Col } from 'components/grid'
import { P } from 'components/typography/Paragraph'
import { FusionUnit } from 'pages/arc-core-fusion'
import React from 'react'
import { twMerge } from 'tailwind-merge'

interface Props {
	fusionUnit: FusionUnit
}

export default function FusionUnitCard({ fusionUnit }: Props) {
	return (
		<Col width="w-full md:w-1/2 xl:w-1/4" className="flex mb-6 xl:mb-0">
			<div className="border rounded-lg flex flex-col w-full overflow-hidden">
				<div className={twMerge('p-4 border-t-[6px]', fusionUnit.textColor)}>
					<span className="text-center font-bold text-2xl block">{fusionUnit.heading}</span>
					<P className="text-center whitespace-pre-line" size="sm">
						{fusionUnit.subHeading}
					</P>
				</div>
				<div className="px-4 py-4">
					<img src={`/images/arc-core-fusion/${fusionUnit.logo}`} alt="arc core fusion logo" className="w-full" />
				</div>
				<div className="px-4 py-4">
					<img src={`/images/arc-core-fusion/${fusionUnit.collection}`} alt="arc core fusion logo" className="w-full" />
				</div>
				<div className="font-light text-center block pb-4 px-4">{fusionUnit.description}</div>
			</div>
		</Col>
	)
}
