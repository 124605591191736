import { Button } from '@arckit/ui'
import { Col, Row, Section } from 'components/grid'

import FusionUnitCard from 'components/arc-core-fusion/FusionUnitCard'
import SectionTitle from 'components/global/SectionTitle'
import Layout from 'components/Layout'
import OverlayVideo from 'components/overlay-video'
import { H1, H3 } from 'components/typography/Headings'
import { P } from 'components/typography/Paragraph'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import EachUnitSection from '../components/arc-core-fusion/EachUnitSection'
import CTAGrid from '../components/forms/CTAGrid'
import GetStartedCTA from '../components/forms/GetStartedCTA'

function goToCta() {
	const input = document.getElementById('get-started-cta-email')
	input?.focus()
}

export default function ArcCoreFusionPage() {
	return (
		<Layout pageName="ARC Core Fusion">
			<Section className="overflow-hidden pt-10 pb-20">
				<div className="absolute top-0 h-full w-full bg-white">
					<div className="rainbow-gradient-light absolute right-0 bottom-0 top-auto h-[250px] w-[250px] rounded-[30%_70%_71%_29%_/_30%_30%_70%_70%] opacity-50 blur-[40px] sm:block md:bottom-auto md:top-8 md:h-[500px] md:w-[500px]"></div>
					<div className="rainbow-gradient-light absolute left-0 right-auto -top-[110px] hidden h-[300px] w-[300px] -translate-x-[50%] translate-y-[20%] rounded-full opacity-50 blur-[40px] sm:block"></div>
				</div>

				<Row center className="relative">
					<Col width="w-full " className="flex items-center justify-center">
						<StaticImage src="../../static/images/arc-core-fusion/arc-core-fusion-logo-color-500.png" alt="arc core fusion logo" />
					</Col>
				</Row>
				<Row className="relative">
					<Col width="w-full md:w-3/5" className="text-center mx-auto">
						<H1 className="mb-4">
							The{' '}
							<span className="relative leading-relaxed whitespace-nowrap text-ab-100 after:absolute after:-bottom-8 after:left-0 after:h-8 after:w-full after:rounded-[50%] after:border-[5px] after:border-transparent after:border-t-core-y sm:leading-normal">
								New{' '}
							</span>{' '}
							ARC Core<sup>®</sup> Middle School
						</H1>
						<P className="">
							We’re thrilled to introduce American Reading Company's innovative new middle school curriculum, designed to be your all-in-one solution for your
							45–60-minute class periods! Grounded in the Science of Reading, our ARC Core Fusion curriculum fosters a vibrant intellectual community where reading,
							writing, thinking, and debating aren't just activities—they're a way of life.
						</P>
					</Col>
				</Row>
			</Section>
			<Section margin="mt-20 md:mt-32">
				<Row>
					<Col width="w-1/2" className="hidden self-end md:block">
						<StaticImage src="../../static/images/arc-core-fusion/arc-fusion-lessons-download.png" alt="arc core fusion teacher samples" />
					</Col>
					<Col width="w-full md:w-1/2" className="self-center">
						<div className="mb-3 h-2 w-40 bg-ab-100 ml-auto"></div>
						<H3 className="mb-6 text-right">Explore the ARC Core Fusion teacher guides by downloading your free samples today!</H3>
						<div className="mb-10 flex items-center justify-end gap-6">
							<Button onClick={goToCta} size="lg">
								Download Samples
							</Button>
						</div>
					</Col>
				</Row>
			</Section>

			{/* <Section margin="mt-20 md:mt-40">
				<Row>
					<Col width="w-full md:w-3/4" className="mx-0 md:mx-auto">
						<OverlayVideo title="ARC Core Fusion" noOverlay />
					</Col>
				</Row>
			</Section> */}

			<Section className="mt-20 md:mt-40">
				<Row>
					<Col width="w-full md:w-7/12" className="self-center">
						<div>
							<H3>Knowledge-Building Units</H3>
							<P className="mb-4 text-ab-100 font-semibold">45–60 minutes daily</P>
							<div>
								<P className="font-semibold mb-4" shade="darkest" size="md">
									In every Unit, students will:
								</P>
								<ul className="list-checkmark space-y-3 pl-6">
									<li>Read and analyze complex texts</li>
									<li>Become proficient writers of a variety of text types</li>
									<li>Build knowledge and vocabulary through deep research into topics in Science, History, and Literary Genres</li>
									<li>Engage in high-volume reading practice</li>
								</ul>
							</div>
						</div>
					</Col>
					<Col width="w-5/12" className="hidden self-end md:block">
						<StaticImage src="../../static/images/arc-core-fusion/new-middle-school-header.png" alt="world mythology framework cover" />
					</Col>
				</Row>
			</Section>

			<Section margin="mt-20 md:mt-40">
				{sections.map((section, sectionIndex) => (
					<React.Fragment key={sectionIndex}>
						<SectionTitle
							title={section.title}
							text={[
								<span key={section.text} className="font-semibold">
									{section.text}
								</span>
							]}
						/>

						<Row className="mb-20 md:mb-32 last:mb-0">
							{section.units.map((unit, unitIndex) => (
								<FusionUnitCard key={unitIndex} fusionUnit={unit} />
							))}
						</Row>
					</React.Fragment>
				))}
			</Section>

			<EachUnitSection />

			<Section margin="mt-20 md:mt-40">
				<Row middle overflow={false}>
					<Col width="w-full sm:w-3/5 xl:w-2/5" className="relative z-0 mx-auto mb-8">
						<GetStartedCTA
							subHeading="Get your teacher guides"
							copy="Research-based strategies, teacher guides, lessons, activities & more."
							pageName="ARC Core Fusion"
							heading="Your Classroom Solution for Engaged Learning"
							underline="Engaged"
							downLoadAfterSubmit="fusion-teacher-samples"
						/>
						<CTAGrid />
						<span className="absolute -top-10 -right-6 z-[-1] hidden sm:inline">
							<svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path fillRule="evenodd" clipRule="evenodd" d="M0 100C0 44.7715 0 0 0 0C55.2285 0 100 44.7715 100 100C100 100 100 100 0 100Z" fill="#0271BC"></path>
							</svg>
						</span>
					</Col>
				</Row>
			</Section>
		</Layout>
	)
}

export interface FusionUnit {
	textColor: string
	heading: string
	subHeading: string
	logo: string
	collection: string
	description: string
}

const fusionUnit: FusionUnit[] = [
	{
		textColor: 'border-t-[#ac6ea7]',
		heading: 'Unit 1',
		subHeading: 'RL/I.1, RL.2, RL.4, RL.5\n' + 'W.1, W.3\n',
		logo: 'gr6-u1-fusion-theme-logo.png',
		collection: 'gr6-u1-fusion-core-texts.png',
		description: 'Read, analyze, and retell World Mythology.'
	},
	{
		textColor: 'border-t-[#787bb4]',
		heading: 'Unit 2',
		subHeading: 'RI.2, RI.3, RI.5, RI.7\nW.2, W.7, W.8',
		logo: 'gr6-u2-fusion-theme-logo.png',
		collection: 'gr6-u2-fusion-core-texts.png',
		description: 'Read informational texts, narrative nonfiction, and related myths to write a research report on Geology.'
	},
	{
		textColor: 'border-t-[#73c0e4]',
		heading: 'Unit 3',
		subHeading: 'RL.2, RL.3, RL.6, RL.9\n' + 'W.1, W.3',
		logo: 'gr6-u3-fusion-theme-logo.png',
		collection: 'gr6-u3-fusion-core-texts.png',
		description: 'Read, compare, analyze, and create Fantasy.'
	},
	{
		textColor: 'border-t-[#efba46]',
		heading: 'Unit 4',
		subHeading: 'RI.6, RI.8, RI.9\n' + 'W.1, W.7, W.8',
		logo: 'gr6-u4-fusion-theme-logo.png',
		collection: 'gr6-u4-fusion-core-texts.png',
		description: 'Research to create written and oral arguments about World Civilizations.'
	},
	{
		textColor: 'border-t-[#d43d8d]',
		heading: 'Unit 1',
		subHeading: 'RL.1, RL.2, RL.4, RL.5\n W.1',
		logo: 'gr7-u1-fusion-theme-logo.png',
		collection: 'gr7-u1-fusion-core-texts.png',
		description: 'Read, analyze, and create Poetry.'
	},
	{
		textColor: 'border-t-[#c42428]',
		heading: 'Unit 2',
		subHeading: 'RI.2, RI.3, RI.5, RI.7\n W.2, W.7, W.8',
		logo: 'gr7-u2-fusion-theme-logo.png',
		collection: 'gr7-u2-fusion-core-texts.png',
		description: 'Read informational and narrative nonfiction as well as poetry of the era to write a research report on the American Revolution & New Nation.'
	},
	{
		textColor: 'border-t-[#DB4C23]',
		heading: 'Unit 3',
		subHeading: 'RL.2, RL.3, RL.6, RL.9\n W.1, W.3',
		logo: 'gr7-u3-fusion-theme-logo.png',
		collection: 'gr7-u3-fusion-core-texts.png',
		description: 'Read, compare, analyze, and create American Historical Fiction both in prose and in verse.'
	},
	{
		textColor: 'border-t-[#e98f35]',
		heading: 'Unit 4',
		subHeading: 'RI.6, RI.8, RI.9,\n W.1, W.7, W.8',
		logo: 'gr7-u4-fusion-theme-logo.png',
		collection: 'gr7-u4-fusion-core-texts.png',
		description: 'Research across texts of all kinds to create written and oral arguments about Westward Expansion.'
	},
	{
		textColor: 'border-t-[#83b341]',
		heading: 'Unit 1',
		subHeading: 'RL.2, RL.4, RL.5,\n W.1, W.3',
		logo: 'gr8-u1-fusion-theme-logo.png',
		collection: 'gr8-u1-fusion-core-texts.png',
		description: 'Read, compare, analyze, and create Science Fiction.'
	},
	{
		textColor: 'border-t-[#18A49C]',
		heading: 'Unit 2',
		subHeading: 'RI.2, RI.3, RI.5, RI.7,\n W.2, W.7, W.8',
		logo: 'gr8-u2-fusion-theme-logo.png',
		collection: 'gr8-u2-fusion-core-texts.png',
		description: 'Read informational and narrative nonfiction to write a research report on Environmental Studies.'
	},
	{
		textColor: 'border-t-[#6fb6d9]',
		heading: 'Unit 3',
		subHeading: 'RL.2, RL.3, RL.6, RL.9,\n W.1, W.3',
		logo: 'gr8-u3-fusion-theme-logo.png',
		collection: 'gr8-u3-fusion-core-texts.png',
		description: 'Read, compare, and analyze World War II Literature.'
	},
	{
		textColor: 'border-t-[#a0a2a6]',
		heading: 'Unit 4',
		subHeading: 'RI.6, RI.8, RI.9,\n W.1, W.7, W.8',
		logo: 'gr8-u4-fusion-theme-logo.png',
		collection: 'gr8-u4-fusion-core-texts.png',
		description: 'Research to create written and oral arguments and narrative texts about Heroes in History.'
	}
]

export const sections = [
	{
		title: 'Grade 6',
		text: 'Civilizations: Real and Imagined',
		units: fusionUnit.slice(0, 4) // First 4 units
	},
	{
		title: 'Grade 7',
		text: 'America Across Genres',
		units: fusionUnit.slice(4, 8) // Next 4 units
	},

	{
		title: 'Grade 8',
		text: 'The Human Condition: Past, Present, and Future',
		units: fusionUnit.slice(8, 12) // Next 4 units
	}
]
